import { FC } from 'react';

import Image from 'next/image';

import { useTheme } from '~/shared/hooks/useTheme';

import { Container } from './styles';

interface IAuthTitleProps {
  title: string;
}

export const AuthTitle: FC<IAuthTitleProps> = ({ title }) => {
  const { logo } = useTheme();

  return (
    <Container>
      <Image src={logo} height={60} width={300} alt="PagLee" />

      <h1>{title}</h1>
    </Container>
  );
};
