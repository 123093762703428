import { NextPage } from 'next';

import { SignIn } from '~/modules/auth/pages/SignIn';

import { withAuthentication } from '~/shared/utils/withAuthentication';

export const getServerSideProps = withAuthentication();

const SignInPage: NextPage = () => {
  return <SignIn />;
};

export default SignInPage;
